<template>
  <!-- <v-card class="white text-center">
    <v-card-title
      class="justify-center text--secondary font-weight-regular text-uppercase"
    >
      Nombres d'inscrits par départements
    </v-card-title>

    <v-card-text> -->
  <!--v-skeleton-loader-- v-skeleton-loader-- type="image, table-row-divider@5"> </!--v-skeleton-loader-->
  <!-- <v-img height="40vh" width="100%" :aspect-ratio="4 / 3"> -->
  <l-map
    :zoom="zoom"
    :center="center"
    :options="mapOptions"
    :min-zoom="minZoom"
    :max-zoom="maxZoom"
  >
    <l-tile-layer :url="url" :attribution="attribution" />

    <l-geo-json
      v-if="show"
      :geojson="geojson"
      :options="options"
      :options-style="styleFunction"
    />
  </l-map>
  <!-- </v-img>
      <list-of-user-by-dep
        class="pa-2"
        v-if="geojson"
        :data="geojson.features"
      ></list-of-user-by-dep>
    </v-card-text>
  </v-card> -->
</template>

<script>
import { latLng } from 'leaflet'
import { LMap, LTileLayer, LMarker, LGeoJson } from 'vue2-leaflet'

export default {
  name: 'mapChart',
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
    LMarker
  },
  props: ['geojson'],
  data() {
    return {
      countPostal: [],
      loading: false,
      show: true,
      enableTooltip: true,
      zoom: 6.1,
      minZoom: 5,
      maxZoom: 8,
      center: [48.68916667, 5.619444444],
      // geojson: null,
      fillColor: 'var(--v-primary-base)',
      url: 'front/src/assets/franceDepartmentsHigh.svg',
      attribution: 'mapChart.net',
      mapOptions: {
        zoomControl: false,
        attributionControl: false,
        zoomSnap: 0.2
      }
    }
  },
  methods: {
    getColor(d) {
      return d > 110
        ? 'var(--v-primary-base)'
        : d > 90
        ? 'var(--v-primary-lighten1)'
        : d > 60
        ? 'var(--v-primary-lighten2)'
        : d > 30
        ? 'var(--v-primary-lighten3)'
        : d > 15
        ? 'var(--v-primary-lighten4)'
        : d > 0
        ? 'var(--v-primary-lighten5)'
        : 'white'
    }
  },
  computed: {
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction
      }
    },

    styleFunction() {
      const fillColor = this.fillColor // important! need touch fillColor in computed for re-calculate when change fillColor
      return feature => {
        return {
          weight: 1,
          color: '#545454',
          opacity: 1,
          fillColor: this.getColor(feature.properties.value),
          fillOpacity: 1
        }
      }
    },
    onEachFeatureFunction() {
      if (!this.enableTooltip) {
        return () => {}
      }
      return (feature, layer) => {
        layer.bindTooltip(
          `<div>${feature.properties.nom} (${feature.properties.code})` +
            `<br>` +
            ` <strong> ${feature.properties.value || 0} </strong> </div>`,
          { permanent: false, sticky: true }
        )
      }
    }
  }
}
</script>

<style scoped>
/deep/ svg {
  background: white;
}
</style>
